@import url('https://fonts.googleapis.com/css2?family=Monda&display=swap');

/* body {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    text-align: center;
} */



body {
    box-sizing :border-box;
    font-family: 'Monda', sans-serif;
}

header {
    padding:17px 0;
    overflow:hidden;
    height: 12vh;
  }
  
  header .logo {
    float: left;
    color: white;
    width: 215px;
    transform: translateY(-67px);
    height: 30vh;
    cursor: pointer;
  }
  
  #logo-title {
    color: black;
    float:left;
    margin-top:15px;
    cursor:pointer;
  }
  
  .menu {
    float:right;
  }
  
  .menu a {
    color: black;
    text-decoration:none;
    display:inline-block;
    padding: 10px 20px;
    border-radius:3px;
    transition: all 0.3s ease-in-out;
    cursor:pointer;
    font-weight: bold;
  }
  
  .menu label {
    display:none;
  }
  
  .menu #toggle {
    display:none;
  }
  
  .menu a:hover {
    background: #FF0000;
    color: #fff;
    cursor:pointer;
  }

h1 {
    margin: 0;
    margin-bottom: 0.5rem;
}

.title {
  color:#FF0000;
  height: 10vh;
 
}


.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 25vh;
    text-align: center;
}

.converter-container {
    width: 48rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#root-size {
  width: 2.5rem;
  border: 2px solid gainsboro;
  font-weight: bold;
  text-align: center;
  font-size: 1.1rem;
}

.input {
    border: 4px solid gainsboro;
    border-radius: .3rem;
    padding: 0.25rem;
    width:22rem;
    display:flex;
    box-sizing: border-box;
    font-size: 2.5rem;
    text-align: center;
}

input:focus {
  border: 4px solid	#FF0000;
  outline:#FF0000;
}

#red {
  color:#FF0000;
}

.numeric {
    position:relative;
    margin-top: 0.9375rem;
}

abbr::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
}

.rem-unit {
    position: absolute;
    display: inline-block;
    font-size: 2rem;
    vertical-align: middle;
    top: 0;
    right: 2rem;
    bottom: 0;
    opacity: 0.5;
    padding-top: 0.2em;
}

.px-unit {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    font-size: 2rem;
    top: 0;
    right: 1rem;
    bottom: 0;
    opacity: 0.5;
    padding-top: 0.2em;
}


select {
    margin-left: 0.5rem;
}

label {
  font-weight:bold
}

.equals {
    margin-top: 2.25rem;
    font-weight: bold;
    font-size: 2rem;
    padding: 0 1.25rem 0 1.25rem;
}

#large-switch {
  width: 2rem;
  height: 2rem;
  margin-top: 2rem;
  cursor: pointer;
}

#small-switch {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  margin-top: 2rem;
  padding: 0 4px 0 4px;
}

.footer-container {
  margin-top: 20rem;
  background: #f4f7f9;
}

.policy-container {
  display:flex;
	justify-content: center;
  height:8vh;
  border-top:2px solid #f4f7f9;
}

#footer-1 {
  margin-top: 1.63rem;
}

#footer-1 a {
  color: 	#FF0000;
  font-weight: bold;
  text-decoration: none;
 
}

#footer-1 a:hover {
 text-decoration: underline;
}

#footer-2 {
  display:flex;
	justify-content: center;
  color:#000;
  padding-bottom: 2rem;
 
}

@media screen and (max-width:1800px) {

  header .logo {
  display: none;
}

}


@media screen and (max-width:475px) {

  header .logo {
  transform: translate(3.13rem, -3.13rem);
}

.title {
  font-size: 1.5rem;
}

.container h2 {
  font-size: 1rem;
}

.container h3 {
  font-size: 0.85rem;
}

.converter-container {
  width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.converter-container label {
  margin-top: 2rem;
}

.input {
  width: 15rem;
  font-size: 1.5rem;
  margin-left: 2.25rem;
}

.equals {
  margin-top: 0.50rem;
}

.rem-unit {
  font-size: 1.2rem;
  right: 4rem;
}

.px-unit {
  font-size: 1.2rem;
  right: 4rem;
}

.policy-container {
  font-size: 0.75rem;
}

#footer-2 {
  font-size: 0.65rem;
  margin-top: 1rem;
  padding-bottom: 4rem;
}
   
 }
 